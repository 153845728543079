import { css } from 'astroturf';
import React from 'react';

const styles = css`
  .testimonial {
    position: relative;
    text-align: left;
    color: #000;
    margin: 2.25rem 1rem 0;
    background: #fbfbfb;
    border-radius: 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, rgba(0, 0, 0, 0.05) 0px 1px 0px 0, rgba(0, 0, 0, 0.03) 0px 0px 8px 0,
      rgba(0, 0, 0, 0.1) 0px 20px 30px 0;
    max-width: 24rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    @media (forced-colors: active) {
      border: 1px solid;
    }

    &.frontendor {
      background: #5e22e6;
      color: #fff;
    }

    &.dark {
      background: #107db5;
      background: #107db5 linear-gradient(to right bottom, rgb(114, 198, 239), rgb(0, 78, 143));
      color: #fff;

      & blockquote::before {
        color: #fff;
      }
    }

    & blockquote {
      margin: 0;
    }

    & blockquote::before {
      content: '“';
      font-size: 10rem;
      color: #107db5;
      opacity: 0.15;
      position: absolute;
      top: -2.5rem;
      left: 0.5rem;
    }

    & p {
      font-weight: 400;
      font-size: 16px;
    }

    & strong {
      font-weight: 600;
    }
  }
`;

function Testimonals(props) {
  return (
    <div className={[styles.testimonial, styles[props.type]].join(' ')}>
      <blockquote>{props.quote}</blockquote>
      {props.children}
    </div>
  );
}

export default Testimonals;
