import { css } from 'astroturf';
import React from 'react';
import { ArrowRight } from 'react-bytesize-icons';
import Link from './link';

const styles = css`
  .punchout {
    font-weight: 800;
    background: linear-gradient(135deg, #4ca2cd, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .about {
    text-align: center;
    color: #000;
    padding: 4.5rem 2rem 4.5rem;

    &.nomargin {
      margin-top: 0;
      padding-top: 4.5rem;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      letter-spacing: -0.015em;
      max-width: 60rem;
    }

    & h3 {
      font-weight: 800;
      font-size: calc(16px + 0.6vw);
      line-height: 1.25;
      margin: 0.5rem 0;
    }
    & p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & a {
      margin: 0;
      padding: 0;
      background: none;
      box-shadow: none;
      flex: 1 1 33%;
      border-radius: 4px;

      &::after {
        display: none;
      }
      &:hocus {
        background: none;
        box-shadow:
          rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
          rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;

        & img {
          filter: drop-shadow(0 10px 10px rgba(50, 50, 93, 0.3));
          transform: scale(1) translateY(-4px);
        }
      }
    }

    & h3 {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 32rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
      color: #0a1530;

      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & div {
      width: 100%;
      align-items: stretch;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      max-width: 1200px;
      margin: 0 auto;
      gap: 3rem 1rem;

      & > a {
        flex: 1 1 30%;
      }

      & h3 {
        max-width: 20rem;
        margin: 1rem auto;

        & img {
          display: block;
          max-width: 192px;
          height: 192px;
          margin: 0 auto 0.5rem;

          transform: scale(0.95);
          transition:
            0.25s ease-in-out filter,
            0.25s ease-in-out transform;
          object-fit: contain;
        }

        & strong {
          display: block;
          margin-bottom: 1rem;
        }

        & .button {
          position: relative;
          display: block;
          pointer-events: all;
          color: #0079b1;
          border-bottom: 1px solid #000;
          line-height: 1.25;
          padding: 0.33em 0;
          display: inline-block;
          text-decoration: none;
          line-height: 26px;
          transition: all 0.25s ease-in-out;
          font-weight: 400;

          &::after {
            position: absolute;
            right: 0px;
            bottom: -1px;
            content: ' ';
            width: 100%;
            height: 1px;
            background: #fff;
            transition: 0.25s ease-out width;
          }

          & svg {
            transform: translateX(0);
            transition: 0.2s 0.12s ease-out;
          }

          & svg {
            vertical-align: bottom;
          }
        }
      }
      @media (min-width: 800px) {
        align-items: stretch;
        flex-direction: row;

        & a {
          max-width: 33%;
        }
      }
    }
  }
`;

function FullCaseStudies(props) {
  return (
    <>
      <div className={[styles.about, props.nomargin ? styles.nomargin : ''].join(' ')}>
        <div>
          {/* <Link to="/blog/how-blhack-doubled-their-development-speed-with-polypane/">
          <h3>
            <img src="/blogs/casestudy-blhack/logo.svg" alt="" />
            <strong>How Blhack doubled their development speed with Polypane</strong>
            <span className={styles.button}>
              Read Case Study
              <ArrowRight height={24} />
            </span>
          </h3>
        </Link> */}
          <Link to="/blog/how-using-polypane-for-nureply-helped-onur-learn-to-love-the-web/">
            <h3>
              <img src="/blogs/casestudy-nureply/logo.png" alt="" />
              <strong>How using Polypane for Nureply helped Onur learn to love the web</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          <Link to="/blog/how-matchless-web-halved-their-mobile-optimization-time/">
            <h3>
              <img src="/blogs/casestudy-matchlessweb/logo.svg" alt="" />
              <strong>How Matchless Web halved their mobile optimization time</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          <Link to="/blog/how-short-hills-design-saves-over-700-per-year-thanks-to-polypane/">
            <h3>
              <img src="/blogs/casestudy-shorthills/logo.png" alt="" />
              <strong>How Short Hills Design saves over $700 per year thanks to Polypane</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          <Link to="/blog/how-teliport-me-is-moving-to-the-web-with-polypane/">
            <h3>
              <img src="/blogs/casestudy-teliportme/logo.svg" alt="" />
              <strong>How TeliportMe is moving to the web with Polypane</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          <Link to="/blog/how-red-pixel-themes-uses-polypane/">
            <h3>
              <img src="/blogs/tailwindmade/cover.svg" alt="" />
              <strong>Red Pixel Themes uses Polypane to create templates 3 times faster.</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          <Link to="/blog/how-we-made-the-state-of-css-more-responsive-and-accessible/">
            <h3>
              <img src="/blogs/stateofcss/cover.svg" alt="" />
              <strong>How we made the State of CSS more responsive and accessible.</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          {/* <Link to="/blog/how-earnworthy-uses-polypane-to-audit-landing-pages-and-land-25-more-customers/">
            <h3>
              <img src="/blogs/earnworthy/earnworthy.svg" alt="" />
              <strong>Earnworthy land 25% more customers by using Polypane in their Audits.</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link> */}
          <Link to="/blog/how-sergio-mattei-made-the-next-makerlog-6-x-faster-with-polypane/">
            <h3>
              <img src="/blogs/makerlog/logo.svg" alt="" />
              <strong>Sergio Mattei made the next Makerlog 6x faster with Polypane.</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
          {/* <Link to="/blog/how-code-increased-their-efficiency-by-500-maintaining-and-improving-websites-like-freshwater-slsc/">
            <h3>
              <img src="/blogs/codeand/cover.svg" alt="" />
              <strong>Code& increased their efficiency by 500% using Polypane.</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link> */}
          <Link to="/blog/how-maurits-meester-combines-front-end-development-with-beer/">
            <h3>
              <img src="/blogs/victorie/maurits.png" alt="" style={{ objectFit: 'contain', borderRadius: '100%' }} />
              <strong>How Maurits Meester combines front-end development with beer.</strong>
              <span className={styles.button}>
                Read Case Study
                <ArrowRight height={24} />
              </span>
            </h3>
          </Link>
        </div>
      </div>
    </>
  );
}

export default FullCaseStudies;
