import { css } from 'astroturf';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  .attrib {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    margin: 2rem 0 0;
    text-align: left;
  }
  .image {
    overflow: hidden;
    position: relative;
    width: 48px !important;
    height: 48px !important;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 48px;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    flex: 0 0 48px;
  }

  .text {
    padding-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    & span,
    & a {
      font-weight: 600;
      font-size: 1rem;
      font-style: normal;
      color: inherit;
      box-shadow: none;
      &::after {
        display: none;
      }
    }

    & em {
      font-style: normal;
    }
  }
`;

function Person(props) {
  return (
    <cite className={styles.attrib}>
      {props.image ? (
        <GatsbyImage
          image={props.image.childImageSharp.gatsbyImageData}
          className={styles.image}
          alt=""
          title={props.name}
        />
      ) : (
        ''
      )}
      <div className={styles.text}>
        <span>{props.name}</span> <em>{props.desc}</em>
      </div>
    </cite>
  );
}

export default Person;
