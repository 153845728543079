import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { ArrowRight } from 'react-bytesize-icons';

import Brow from './Brow';
import Punchout from './Punchout';
import Link from './link';
import Person from './testimonials/Person';
import Testimonial from './testimonials/Testimonial';

const styles = css`
  .testimonals {
    position: relative;
    padding: 5rem 0 0;
    text-align: center;
    color: #0a1530;

    &.dark,
    &.dark h2,
    &.dark > p {
      color: #fff;
    }

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      letter-spacing: -0.015em;
      max-width: 60rem;
    }

    & > p {
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
      font-size: 1rem;
    }
  }

  .quotes {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;
    max-width: 80rem;
    overflow: auto;
    padding: 2rem 0.5rem 3rem;
    scroll-snap-type: x mandatory;

    &::after {
      width: 1px;
      height: 1px;
      display: block;
      content: ' ';
      flex: 0 0 1px;
    }

    & > div {
      margin: 0 0.5rem;
      scroll-snap-align: center;
      flex: 1 0 calc(100vw - 6rem);
      min-width: 18rem;
    }

    @media (min-width: 60rem) {
      scroll-snap-type: unset;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 1rem;
      padding-right: 1rem;
      justify-content: center;
      padding-bottom: 2rem;
      overflow: visible;

      & > div {
        margin: 0.5rem;
      }
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: #0079b1;
    padding: 0.33em 1rem;
    box-shadow: none;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }

    & svg {
      vertical-align: middle;
    }
  }
`;

function Testimonals(props) {
  const HayQuote = (
    <p>
      <strong>Polypane is easily one of the most useful tools for modern web designers and developers.</strong>
    </p>
  );

  const saraQuote = (
    <p>
      It is convenient seeing your work in multiple responsive views at the same time.{' '}
      <strong>
        Love it, <i>💯</i>
      </strong>
    </p>
  );

  const graemeQuote = (
    <p>
      Polypane is fast, <strong>helps me catch issues</strong> and the meta information preview is{' '}
      <strong>awesome</strong>.
    </p>
  );

  const sergioQuote = (
    <p>
      Using Polypane and woah this is awesome! It’s <strong>really slick and fast!</strong> I was just 5 minutes in and
      I already fixed a bug.
    </p>
  );

  const vivianQuote = (
    <p>
      This thing is FAST and the synchronized scrolling works amazingly. <strong>Prepare to be impressed.</strong>
    </p>
  );

  const kevinQuote = (
    <p>
      I've started playing around with Polypane and 🤯. It has{' '}
      <strong>awesome debugging tools, accessibility checks and tests</strong>, and more. I'm really impressed.
    </p>
  );

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => (
        <div className={[styles.testimonals, props.dark ? styles.dark : ''].join(' ')}>
          <Brow>Testimonials</Brow>
          <h2>
            Developers and designers <Punchout>love</Punchout> Polypane.
          </h2>
          <p>Here's what they say:</p>
          <div className={styles.quotes}>
            <Testimonial quote={HayQuote}>
              <Person
                image={data.stephen}
                name="Stephen Hay"
                url="http://the-haystack.com/"
                desc="Author of Responsive Design Workflow"
              />
            </Testimonial>

            <Testimonial quote={saraQuote} type="dark">
              <Person
                image={data.sara}
                name="Sara Soueidan"
                url="https://www.sarasoueidan.com/"
                desc="Independent front-end UI engineer, Speaker/Trainer"
              />
            </Testimonial>

            <Testimonial quote={kevinQuote}>
              <Person image={data.kevin} name="Kevin Powell" url="https://kevinpowell.co" desc="CSS Evangelist" />
            </Testimonial>
          </div>

          <p>
            <Link className={styles.primaryButton} to="/testimonials/">
              <span>
                Read more testimonials <ArrowRight height={24} />
              </span>
            </Link>
          </p>
        </div>
      )}
    />
  );
}

export default Testimonals;

const detailsQuery = graphql`
  query SmallTestimonalsQuery {
    stephen: file(relativePath: { eq: "images/hay.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    sara: file(relativePath: { eq: "images/sara.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
    kevin: file(relativePath: { eq: "images/kevin.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 48, quality: 90, layout: FIXED)
      }
    }
  }
`;
