import React from 'react';
import AwardRow from '../components/Accolades/ImageRow';
import Brands from '../components/Brands';
import CTA from '../components/CTA';
import CaseStudies from '../components/FullCaseStudies';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Testimonals from '../components/SmallTestimonials';
import Layout from '../layout';

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Case Studies" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1>Case Studies</h1>
          <h2>Let our customers tell you what Polypane did for their organisation</h2>
        </PageHeader>
        <CaseStudies />
        <Testimonals />
        <AwardRow />
        <Brands />
        <CTA />
      </Layout>
    );
  }
}

export default Index;
