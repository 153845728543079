import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { ArrowRight } from 'react-bytesize-icons';

import Brow from '../Brow';
import Link from '../link';

const styles = css`
  .brands {
    position: relative;
    padding: 5rem 2rem 5rem;
    padding-left: calc(2rem + env(safe-area-inset-left, 0));
    padding-right: calc(2rem + env(safe-area-inset-right, 0));
    text-align: center;
    color: #0a1530;
    margin: 0 auto;
    max-width: 1100px;
    z-index: 1;
    display: none;

    @media (min-width: 600px) {
      display: block;
    }

    & h3 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      max-width: 60rem;
    }

    & p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & > div {
      position: relative;
      margin: 1rem 1.5rem 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;

      & > div,
      & > svg {
        margin: 1rem 1.5rem 0;
      }
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 2rem 0 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: #0079b1;
    padding: 0.33em 1rem;
    box-shadow: none;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }

    & svg {
      vertical-align: middle;
    }
  }
`;

function Brands(props) {
  return (
    <div className={[styles.brands, props.negtop ? styles.negtop : '', props.blend ? styles.blend : ''].join(' ')}>
      <Brow>Accolades</Brow>
      <h3>Recognition and awards for Polypane</h3>
      <p>From some of the leading organisations.</p>
      <div>
        <StaticImage
          src="../../../assets/images/awards/worldfestival.png"
          alt=""
          objectFit="contain"
          placeholder="none"
          width={150}
          imgStyle={{ transition: 'none' }}
        />
        <StaticImage
          src="../../../assets/images/awards/corporatevision.png"
          alt=""
          objectFit="contain"
          placeholder="none"
          width={150}
          imgStyle={{ transition: 'none' }}
        />
        <StaticImage
          src="../../../assets/images/awards/csstricks.png"
          alt=""
          objectFit="contain"
          placeholder="none"
          width={150}
          imgStyle={{ transition: 'none' }}
        />
      </div>
      <Link className={styles.primaryButton} to="/accolades/">
        <span>
          Read more testimonials <ArrowRight height={24} />
        </span>
      </Link>
    </div>
  );
}

export default Brands;
